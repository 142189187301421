







































import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'SupportList'
})

export default class extends Vue {
  private videos = [
    {
      title: 'Μέρος 1: Χτίζοντας πρωταθλήτριες ομάδες',
      description: '',
      url: 'https://www.youtube.com/watch?v=35SG9zmO_ZI'
    },
    {
      title: 'Μέρος 2: Χτίζοντας πρωταθλήτριες ομάδες',
      description: '',
      url: 'https://www.youtube.com/watch?v=OPg4drf9mdg'
    },
    {
      title: 'Μέρος 3: Χτίζοντας πρωταθλήτριες ομάδες',
      description: '',
      url: 'https://www.youtube.com/watch?v=5ZFWOj4vnnQ'
    }
  ]

  // Convert regular YouTube URLs to embed URLs
  private getEmbedUrl(url: string): string {
    const videoId = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)[2].split(/[^0-9a-z_\-]/i)[0]
    return `https://www.youtube-nocookie.com/embed/${videoId}`
  }
}
